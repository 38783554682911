import './App.css';
import {
  Routes,
  Route
} from 'react-router-dom';
import { Suspense, lazy } from "react";
import Payments from './pages/Payments';
import { useAuth } from './context/AuthContext';
import { useConfigureAxios } from './services/interceptor';

const Login = lazy(() => import("./pages/Login"));
const Accounts = lazy(() => import("./pages/Accounts"));
const Platform = lazy(() => import("./pages/Platform"));
const Localization = lazy(() => import("./pages/Localization"));
const Blog = lazy(() => import("./pages/Blog"));
const NewPost = lazy(() => import("./pages/NewPost"));
const Templates = lazy(() => import("./pages/Templates"));
const EditTemplate = lazy(() => import("./pages/EditTemplate"));
const Reviews = lazy(() => import("./pages/Reviews"));

function App() {

  useConfigureAxios();

  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      <Route path="/" element={
        isAuthenticated ? <Suspense>
          <Accounts />
        </Suspense> :
          <Suspense>
            <Login />
          </Suspense>
      } />
      <Route path="/platform" element={
        <Suspense>
          <Platform />
        </Suspense>} />
      <Route path="/localization" element={
        <Suspense>
          <Localization />
        </Suspense>} />
      <Route path="/blog" element={
        <Suspense>
          <Blog />
        </Suspense>} />
      <Route path="/templates" element={
        <Suspense>
          <Templates />
        </Suspense>} />
      <Route path="/edit-template/:id" element={
        <Suspense>
          <EditTemplate />
        </Suspense>} />
      <Route path="/post/:id" element={
        <Suspense>
          <NewPost />
        </Suspense>} />
      <Route path="/payments" element={
        <Suspense>
          <Payments />
        </Suspense>} />
      <Route path="/reviews" element={
        <Suspense>
          <Reviews />
        </Suspense>} />
    </Routes>
  );
}

export default App;
