import { PriceUpdateType } from "../pages/types";
import Interceptor from "./interceptor";

export const getPrices = async () => {
    return (await Interceptor.get('/admin/products')).data;
}

export const updatePrice = async (item: PriceUpdateType[]) => {
    return (await Interceptor.put(`/admin/products/prices`, item)).data;
}

export const getPayments = async (search: string, page: number) => {
    return (await Interceptor.get(`/admin/payments/list?search=${search}&page=${page}`)).data;
}

export const getPaymentsChart = async (startDate: string, endDate: string) => {
    return (await Interceptor.post(`/admin/payments`, { startDate, endDate })).data;
}