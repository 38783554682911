import React, { createContext, useContext, useState, useEffect } from 'react';
import { requestLogout } from '../services/auth';

interface AuthContextType {
    isAuthenticated: boolean;
    refresh: () => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    const checkAuthFromCookie = () => {
        const refreshToken = document.cookie.split('; ').find(row => row.startsWith('token.refresh='));
        setIsAuthenticated(!!refreshToken);
    };

    useEffect(() => {
        checkAuthFromCookie();
    }, []);

    const logout = async () => {
        await requestLogout();
        setIsAuthenticated(false);
        window.location.href = '/';
    };

    const refresh = async () => {
        checkAuthFromCookie();
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, refresh, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}; 