import axios from 'axios';
import { baseUrl } from '../constants';
import { useAuth } from '../context/AuthContext';

const instance = axios.create({
    baseURL: `${baseUrl}/api`,
    withCredentials: true,
});

export const configureAxios = (logoutCallback: () => void) => {
    instance.interceptors.response.use(
        response => response,
        async error => {
            const { status, config: originalRequest } = error?.response || {};

            if ([401, 403].includes(status)) {
                if (['/auth/login', '/auth/register', `${baseUrl}/api/admin/auth/refresh`].includes(originalRequest.url)) {
                    return Promise.reject(error);
                }
                try {
                    await axios.post(`${baseUrl}/api/admin/auth/refresh`, {}, { withCredentials: true });
                    return axios(originalRequest);
                } catch (refreshError) {
                    logoutCallback();
                    return Promise.reject(refreshError);
                }
            }

            return Promise.reject(error);
        }
    );
};

export const useConfigureAxios = () => {
    const { logout } = useAuth();
    configureAxios(logout);
};

export default instance;